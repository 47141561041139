import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "What is wrongful death and who can make a legal claim?",
    heroH1: "What is wrongful death and who can make a legal claim?",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              <strong>April 28, 2022</strong> by Frank Bartlett
            </p>
            <h2>What is wrongful death and who can make a legal claim?</h2>
            <p className="mb-8">
              Connecticut residents who face the shock and loss of a loved one
              dying in an accident will wonder what the future holds. As they
              come to grips with what happened, the gravity of the situation
              will become increasingly real and they need to assess how they
              will move forward. While it is hard to think about, there will be
              financial consequences for an unexpected death. Considering filing
              a wrongful death lawsuit is an option. With wrongful death claims,
              however, it is essential to be fully aware as to{" "}
              <a
                href="https://www.cga.ct.gov/current/pub/chap_925.htm"
                rel="noreferrer"
                target="_blank"
              >
                what state law says
              </a>{" "}
              as to who can file and other key legal factors.
            </p>

            <h2>
              Connecticut has certain requirements with a wrongful death claim
            </h2>
            <p className="mb-8">
              In Connecticut, when there has been an accident with a fatality,
              an executor or administrator of the estate of the decedent must be
              appointed by the court to seek compensation from whomever is
              deemed legally responsible for the death. For example, if a drunk
              driver crashed into the person’s vehicle and killed them, then
              that driver can be sued, as can the bar that overserved the
              driver. They can seek compensation for medical care and funeral
              expenses, as well as the pre-death pain and suffering and the loss
              of the ability to enjoy a full life, for the person who passed
              away. The claim must be made within two years of the person’s
              death and no later than five years of the act that led to the
              person’s death. In addition to the claim being made on behalf of
              the estate, a spouse who has lost a husband or wife can also sue
              for loss of consortium as part of the case. That can include the
              loss of moral support, intimate relations, companionship and more
              that came about because of the person’s untimely death.{" "}
            </p>
            <h2>Key points about a wrongful death</h2>
            <p className="mb-8">
              If another person was guilty of neglect, omission or committed a
              willful or wrongful act that resulted in another person’s death,
              then it is categorized as a wrongful death. People are frequently
              uncertain if they have a wrongful death claim. Since it can be
              confusing, it is important to have assistance with analyzing the
              situation and deciding. If the person who died had a will, the
              person named in the will as the executor of the estate is
              typically appointed by the Court to pursue a wrongful death claim.
              If that person refuses to serve as the executor of the estate, or
              if the person who died did not have a will, anyone who is related
              to the person who died can request to be appointed by the Court to
              file the wrongful death claim on behalf of the estate. That
              includes parents, offspring, spouses and other family members. The
              case can yield various financial compensation for losses including
              financial and personal.
            </p>
            <h2>
              Comprehensive, compassionate assistance can help with a wrongful
              death case
            </h2>
            <p className="mb-8">
              When a family has lost a loved one due to the actions or
              negligence of another, they will be confronted with a seemingly
              endless series of concerns as they strive to move forward. From
              the lost companionship to the medical expenses accrued after the
              incident and the decline in income, it can be emotionally,
              personally and financially draining. People need guidance when
              they have been impacted in this way and professional caring
              assistance every step of the way can be crucial. From the start,
              those who have lost a loved one need to know they have protection
              to fight for their rights. Since Connecticut is unique and
              specific in how it handles{" "}
              <Link to="/ct-wrongful-death/">wrongful death cases</Link>, it is
              imperative to be prepared from the outset. That is why Bartlett &
              Grippe provides comprehensive advice and representation from start
              to end. Whether there is a settlement, or the case goes to trial –
              we are here to help every step of the way.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
